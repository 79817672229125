.mobileNavbar{
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    padding-top: 8px;
    padding-bottom: 8px;
    background: white;

    z-index: 9;

    -webkit-box-shadow: 0px 9px 14px -4px rgba(0,0,0,0.18);
}

@media (min-width: 1200px) {
    .mobileNavbar{
        display: none;
    }
}


.logo{
    height: 55px;
    display: block;
    margin: 0 auto;
    filter: brightness(0);
}


.cartWrp{
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
}

.burgerWrp{
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(0, -50%);
}

.cartWrp, .burgerWrp{
    cursor: pointer;
    transition: opacity .3s ease;
}

.cartWrp:hover, .burgerWrp:hover{
    opacity: 0.6;
}

.cartIcon, .burgerIcon{
    margin-right: 10px;
    color: #707070;
    font-size: 20px;
}

.cartPrice{
    color: #707070;
}

.cartIconWrp{
    position: relative;
}

.cartAmount{
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;

    top: -10px;
    right: 2px;

    background: #F9B72E;
    color: white;
}

