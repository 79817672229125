.Logo{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    margin: auto;

    width: 80%;
    height: 80%;

    object-fit: contain;
}

.LogoWrp{
    position: absolute;

    width: 100%;
    padding-top: 80%;

    background: #383c40;

    z-index: 2;
}

.navItemsOuterWrp,
.cartWrp{
    padding-top: 27.5px;
    padding-bottom: 27.5px;
}

.navItemsInnerWrp{
    display: flex;
    align-items: center;
    margin-left: 30px;
}

.navItem{
    font-size: 16px;
    list-style-type: none;
    margin-right: 18px;
    color: #707070;
}

.cartIcon{
    margin-right: 10px;
    color: #707070;
    font-size: 25px;
}

.cartPrice{
    color: #707070;
}

.cartIconWrp{
    transition: opacity .2s ease;
    cursor: pointer;
}

.cartIconWrp:hover{
    opacity: 0.6;
}

.cartAmount{
    position: absolute;

    width: 23px;
    height: 23px;

    border-radius: 50%;

    top: -10px;
    right: 2px;

    background: #F9B72E;
    color: white;
}

.langImg{
    height: 30px;
    margin-left: 20px;
    transition: opacity .3s ease;
}

.langImg:hover{
    opacity: 0.6;
}

.cartFixedWrp{
    position: fixed;
    top: 10px;
    right: 35px;

    width: 60px;
    height: 60px;

    background: white;
    border-radius: 50%;

    z-index: 4;

    box-shadow: 2px 2px 20px 0px rgba(0,0,0,0.4);

    cursor: pointer;

    transition: opacity 0.3s ease;
}

.cartFixedWrp:hover{
    opacity: 0.7;
}


.cartFixedInnerWrp{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.desktopWrp{
    width: 100%;
}

.desktopNavbar{
    position: relative;
    z-index: 9;
    background: white;
    width: 100%;
    display: none;
}

.dropdownArrow{
    color: #F9B72E;
}

@media (min-width: 1200px) {
    .desktopNavbar{
        display: block;
    }
}
