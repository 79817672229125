.loader{
    position: fixed;
    left: 0;
    top: 0;

    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #383c40;

    margin: 0;

    z-index: 99999999;
}
.wineglass {
    height: 36px;
    position: relative;
}
.wineglass:after {
    content: '';
    position: absolute;
    top: 47px;
    left: 5px;
    width: 20px;
    height: 5px;
    background: white;
    box-shadow: 0 0 1px white;
}
.top {
    background: white;
    width: 30px;
    height: 36px;
    border-radius: 0 0 36px 36px;
    box-shadow: 0 0 1px white;
}
.top:before {
    content: '';
    position: absolute;
    left: 4px;
    bottom: 4px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: linear-gradient(transparent 50%, #F9B72E 50%);
}
.left .top:before {
    animation: rotate2 2s linear infinite;
}
.right .top:before {
    animation: rotate1 2s linear infinite;
}
.top:after {
    content: '';
    position: absolute;
    top: 35px;
    left: 12px;
    width: 6px;
    height: 13px;
    background: white;
    box-shadow: 0 0 1px white;
}
.left {
    display: inline-block;
    margin-right: 10px;
    animation: rotate1 2s cubic-bezier(.39,1.52,.46,.92) infinite;
}
.right {
    display: inline-block;
    animation: rotate2 2s cubic-bezier(.39,1.52,.46,.92) infinite;
}
@keyframes rotate1{
    0% {transform: rotate(0deg);}
    50% {transform:rotate(22deg);}
}
@keyframes rotate2{
    0% {transform:rotate(0deg);}
    50% {transform:rotate(-22deg);}
}
.loader ul {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 70px;
    height: 25px;
    position: relative;
    opacity: 0;

    animation: fadeInUp 2s infinite linear;
}
.loader li {
    width: 6px;
    height: 15px;
    background: white;
    position: absolute;
    box-shadow: 0 0 1px white;
    transform-origin: center;
}
.loader li:nth-child(1) {
    left: 26px;
    bottom: 5px;
    transform: rotate(-35deg);
}
.loader li:nth-child(2) {
    left: 34px;
    bottom: 8px;
}
.loader li:nth-child(3) {
    left: 42px;
    bottom: 5px;
    transform: rotate(35deg);
}
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: scaleY(1);
    }

    33% {
        opacity: 1;
        transform: scaleY(1.4);
    }
    64% {
        opacity: .1;
        transform: scaleY(1);
    }
    100% {
        opacity: 0;
        transform: scaleY(.3);
    }
}

.wrp{
    transform: translateY(-50%);
    width: 150px;
    height: 150px;
    position: relative;

    border-radius: 50%;
    border: 3px solid #ffffff;
}

.innerWrp{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -55%);
}