.item::before{
    content: "";

    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;

    background: #F9B72E;

    margin-right: 10px;
}

.item{
    list-style-type: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    font-size: 16px;

    margin-bottom: 5px;
}

.item a {
    color: inherit;
    text-decoration: underline;
}

.item a:hover{
    opacity: 0.5;
}