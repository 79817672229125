@font-face {
  font-family: 'englishBold';
  src: url("assets/fonts/Roboto-Bold.ttf");
}

@font-face {
  font-family: 'englishReg';
  src: url("assets/fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: 'georgianBold';
  src: url("assets/fonts/bpg_nino_mtavruli_bold.ttf");
}

@font-face {
  font-family: 'georgianReg';
  src: url("assets/fonts/bpg_arial.ttf");
}



html{
  font-size: 14px;
}

body{
  padding-top: 71px;
  margin-left: 0;
  margin-right: 0;
}

html, body{
  height: 100%;
}

#root{
  height: 100%;
}

@media(min-width: 1200px){
  body{
    padding-top: 0;
  }
}

@media(min-width: 576px){
  body{
    margin-left: 30px;
    margin-right: 30px;
  }
}

a:hover{
  text-decoration: none;
}

h1,h2,h3,h4,h5,h6,p{
  margin-bottom: 0;
}

@media(min-width: 1500px){
  .h2, h2{
    font-size: 2.5rem;
  }

  .h1, h1{
    font-size: 3.5rem;
  }
}

.react-datepicker-wrapper{
  width: 100%;
}

.cursor-pointer{
  cursor: pointer;
}
