.wrp{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}

.wrp::before{
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3);
}

.innerWrp{
    width: 100%;
    padding-top: 40%;
    position: relative;
}

.text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    color: white;
}

@media (min-width: 992px) {
    .innerWrp{
        padding-top: 20%;
    }
}