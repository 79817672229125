.wrp{
    background: white;
    padding: 20px;
    text-align: center;
    height: 100%;

    display: flex;
    flex-flow: column;
    justify-content: space-between;
}

.name{
    font-size: 1.8rem;
    margin-bottom: 10px;
}

.desc{
    margin-bottom: 25px;
    color: #b6b6b6;
}

.imgWrp{
    position: relative;
    width: 100%;
    padding-top: 110%;
    background: #fff;
    border-bottom: 1px solid #d9d9d9;
}

.img{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    width: 90%;
    height: 80%;

    object-fit: contain;
}

.productWrp{
    margin-bottom: 60px;
    /*height: 100%;*/
}

.innerWrp{
    box-shadow: 0 0 25px 0 rgba(0,0,0,.25);

    height: 100%;
    display: flex;
    flex-flow: column;

    position: relative;
}

.outOfStock{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 40px;

    background: rgba(0,0,0,0.6);
    color: white;
    z-index: 3;

    display: flex;
    justify-content: center;
    align-items: center;

}

.outOfStockText{
    width: 120px;
    height: 120px;
    padding: 10px;
    background: #3C3C3C;

    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 20px;

    box-sizing: content-box;
}