.wrp{
    margin: 50px 0 0;
}

.title{
    padding: 20px 20px;
    background: #2e3034;
    color: white;
}

.content{
    background: white;
    padding: 20px 20px;
}

.summaryLeft{
    padding: 20px 20px 0;
    font-size: 0.95rem;
    font-weight: bold;
    width: 150px;
}

.summaryRight{
    padding: 20px 20px 0;
    font-size: 0.95rem;
    font-weight: bold;
    text-align: right;
}