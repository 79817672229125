.custom{
    border: 2px solid #707070;
    color: #707070;
    background: transparent;
}

.custom::before{
    background: #707070;
}

.custom:hover{
    color: white;
}