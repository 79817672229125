.sliderWrp{
    position: relative;
    /*height: auto;*/
    overflow: hidden;

}

.swiperContainer{
   height: 100%;
}

.swiperWrp{
    width: 100%;
    height: 100%;
}

.bgGray{
    background: #F3F4F4;
}

.swiperSlide{
    height: auto;
    background-position: center;
    background-size: cover;

    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    min-height: 60vw;
}

@media(min-width: 1200px){
    .swiperSlide{
        min-height: 45vw;
    }
}


/*@media(min-width: 576px){*/
/*    .swiperContainer{*/
/*        width: calc(540px + (100% - 540px)/2 - 85px);*/
/*    }*/
/*}*/

/*@media(min-width: 768px){*/
/*    .swiperContainer{*/
/*        width: calc(720px + (100% - 720px)/2 - 85px);*/
/*    }*/
/*}*/

/*@media(min-width: 992px){*/
/*    .swiperContainer{*/
/*        width: calc(960px + (100% - 960px)/2 - 85px);*/
/*    }*/

/*    .sliderWrp{*/
/*        height: calc( 100vh - 130px);*/
/*    }*/
/*}*/

/*@media(min-width: 1200px){*/
/*    .swiperContainer{*/
/*        width: calc(1140px + (100% - 1140px)/2 - 85px);*/
/*    }*/
/*}*/



/*content box*/
.contentBoxWrp{
    width: 90%;
    padding-top: 25px;
}



.contentBoxBtnWrp{
    display: flex;
    justify-content: flex-end;
}



.contentBox{
    background: rgba(46, 48, 52, 0.87);
    padding: 2.5rem;
}

.contentBoxBtn{
    width: 70px;
    height: 70px;
    position: relative;
    background: rgba(46, 48, 52, 0.87);
    transition: opacity .25s ease;

    cursor: pointer;
}

.contentBoxBtn:last-child{
    border-left: 1px solid white;
}


.contentBoxBtn i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    font-size: 20px;

    color: white;

    transition: all .3s ease;
}

.contentBoxBtn:hover i{
    color: #F9B72E;
}

.contentBoxBtn:first-child:hover i{
    transform: translateX(-75%) translateY(-50%);
}

.contentBoxBtn:last-child:hover i{
    transform: translateX(-25%) translateY(-50%);
}


.title{
    color: white;
}

.text{
    color: white;
    margin-top: 15px;
}


@media (min-width: 576px) {
    .contentBoxWrp {
        width: 75%;
    }

    .contentBox{
        background: #383c40;
    }

    .contentBoxBtn{
        background: #2e3034;
    }
}

@media (min-width: 768px) {
    .contentBoxWrp {
        width: 65%;
    }
}

@media(min-width: 992px){

    .contentBoxWrp {
        width: 45%;
    }
}