.wrp{
    padding-top: 90px;
}

.secondaryText{
    font-size: 18px;
    color: #F9B72E;
}

.mainText{
    margin-top: 5px;
    margin-bottom: 10px;
}

.productsWrp{
    margin-top: 60px;
}